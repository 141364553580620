import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";

const footer = () => {
  return (
    <>
      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* <!-- Logo --> */}
              <Link href="/">
                <a className="mb-6 inline-block">
                  <img
                    src="/images/logo.png"
                    className="dark:hidden"
                    alt="Relay | Cross-Border Payments"
                  />
                </a>
              </Link>

              <Link href="/">
                <a className="mb-6 inline-block">
                  <img
                    src="/images/logo_white.png"
                    className="hidden dark:block mb-6"
                    alt="Relay | Cross-Border Payments"
                  />
                </a>
              </Link>
              <p className="dark:text-jacarta-300 mb-12">
                Empowering small merchants with seamless cross-border payments
                through smart wallet technology and innovative security
                solutions.
              </p>

              {/* <!-- Socials --> */}
              <div className="flex space-x-5">
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
            </div>

            {/* Update footer menu items */}
            <div className="col-span-full sm:col-span-3 md:col-span-2">
              <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                Platform
              </h3>
              <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                <li>
                  <Link href="/merchants">
                    <a className="hover:text-accent dark:hover:text-white">
                      For Merchants
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/features">
                    <a className="hover:text-accent dark:hover:text-white">
                      Features
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/security">
                    <a className="hover:text-accent dark:hover:text-white">
                      Security
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/pricing">
                    <a className="hover:text-accent dark:hover:text-white">
                      Pricing
                    </a>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-span-full sm:col-span-3 md:col-span-2">
              <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                Support
              </h3>
              <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                <li>
                  <Link href="/help">
                    <a className="hover:text-accent dark:hover:text-white">
                      Help Center
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/documentation">
                    <a className="hover:text-accent dark:hover:text-white">
                      Documentation
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/api">
                    <a className="hover:text-accent dark:hover:text-white">
                      API Reference
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/contact">
                    <a className="hover:text-accent dark:hover:text-white">
                      Contact Us
                    </a>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-span-full sm:col-span-3 md:col-span-2">
              <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">
                Company
              </h3>
              <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                <li>
                  <Link href="/about">
                    <a className="hover:text-accent dark:hover:text-white">
                      About Us
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/careers">
                    <a className="hover:text-accent dark:hover:text-white">
                      Careers
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/blog">
                    <a className="hover:text-accent dark:hover:text-white">
                      Blog
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/press">
                    <a className="hover:text-accent dark:hover:text-white">
                      Press
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <Link href="/terms">
                  <a className="hover:text-accent dark:hover:text-white">
                    Terms of Service
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/privacy">
                  <a className="hover:text-accent dark:hover:text-white">
                    Privacy Policy
                  </a>
                </Link>
              </li>
            </ul>
            <span className="dark:text-jacarta-400 text-sm">
              © {new Date().getFullYear()} Relay. All rights reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
