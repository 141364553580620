import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "MontaQ Labs | Web3 Infrastructure Solutions",
  keyword:
    "blockchain, web3, crypto infrastructure, IoT, NFC payments, decentralized technology, digital transformation, blockchain SDK, parachain, digital payments, web2 integration, technology innovation",
  desc: "MontaQ Labs bridges web3 and web2 with cutting-edge technological solutions, delivering innovative infrastructure for decentralized applications, IoT networks, and seamless digital transactions.",
};

export default Meta;
